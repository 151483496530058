import React from 'react';

const MonitorTaskContainer = Loader.loadBusinessComponent('MonitorTasks', 'MonitorTaskContainer')

const EventMonitorTask = () => (
  <MonitorTaskContainer 
    libType={3}
    taskType='101503'
    currentMenu='eventTaskView'
    moduleName='eventTaskManage'
  />
)

export default EventMonitorTask